<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增':'修改'" :close-on-click-modal="false" @closed="cancel" width="440px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="110px" v-if="form" :disabled="disabled">
        <el-form-item prop="erpCode" label="ERP编码" style="width: 100%;" v-if="$erp.enable()">
          <el-input v-model.trim="form.erpCode" :maxlength="20" @keypress.enter.native="handleQueryByERP" :readonly="searching">
            <el-button slot="append" icon="el-icon-search" v-loading="searching" :disabled="!form.erpCode" @click="handleQueryByERP"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="计量单位编码" prop="code">
          <el-input v-model.trim="form.code" :maxlength="40" />
        </el-form-item>
        <el-form-item label="计量单位名称" prop="name">
          <el-input v-model.trim="form.name" :maxlength="25" />
        </el-form-item>
        <!-- <el-form-item label="计量单位组" prop="groupCode">
          <quick-select v-model="form.groupCode" filterable url="api/dictDetail?dictName=unit_group" display-field="label" placeholder="请选择" style="width: 100%;" />
        </el-form-item>
        <el-form-item label="是否主单位" prop="mainFlag">
          <el-switch v-model="form.mainFlag" />
        </el-form-item>
        <el-form-item label="换算率" prop="changeRate">
          <el-input-number v-model.trim="form.changeRate" :min="0" :max="9999" :step="0.1" :precision="3" controls-position="right" style="width: 100%;" />
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave" v-if="form && user.entId===form.entId">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit,getByErpCode } from "@/api/unit";
import { mapGetters } from "vuex";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      searching:false,
      form: {
        name: null,
        code: null,
        groupCode: null,
        mainFlag: null,
        changeRate: null,
        erpCode:null,
        entId:null,
      },
      rules: {
        name: [
          { required: "true", message: "计量单位名称为必填项", trigger: "blur" }
        ],
        code: [
          { required: "true", message: "计量单位编码为必填项", trigger: "blur" }
        ],
        groupCode: [
          { required: "true", message: "请选择计量单位组", trigger: "change" }
        ],
        mainFlag: [
          { required: "true", message: "请选择换算率为", trigger: "change" }
        ],
        changeRate: [
          { required: "true", message: "换算率为必填项", trigger: "blur" }
        ],
        erpCode: [
          { required: "true", message: "erp编码为必填项", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    handleQueryByERP(){
        if (this.form.erpCode) {
          this.searching = true;
          this.form.name = "";
          getByErpCode(this.form.erpCode)
            .then(res => {
              this.form.name = res.name;
              this.form.code = res.code;
            })
            .finally(_ => {
              this.searching = false;
            });
        }
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          add(this.form)
            .then(res => {
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          edit(this.form)
            .then(res => {
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        name: null,
        code: null,
        groupCode: null,
        mainFlag: null,
        changeRate: null,
        entId: this.user.entId
      };
    }
  }
};
</script>

