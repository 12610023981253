<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-model="query.value" clearable placeholder="根据计量单位名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <!--  -->
      <!-- <el-select v-model="query.status" filterable clearable placeholder="单位组" class="filter-item" style="width: 90px" @change="toQuery">
        <el-option v-for="item in dictMap.unit_group" :key="item.id" :label="item.label" :value="item.id" />
      </el-select>-->

      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-upload2" @click="doImport">导入</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column type="index" label="序号" fixed />
      <el-table-column prop="erpCode" label="ERP编码" v-if="$erp.enable()" />
      <el-table-column prop="code" label="计量单位编码" :show-overflow-tooltip="true" />
      <el-table-column prop="name" label="计量单位名称" :show-overflow-tooltip="true" />
      <!-- <el-table-column prop="groupCode" label="计量单位组" :show-overflow-tooltip="true" :formatter="getGroupName" /> -->
      <!-- <el-table-column prop="mainFlag" label="主计量单位" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.mainFlag === true" :type="scope.row.mainFlag ? '' : 'info'">是</el-tag>
          <el-tag v-if="scope.row.mainFlag === false" :type="scope.row.mainFlag ? '' : 'info'">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="changeRate" label="换算率" :show-overflow-tooltip="true" />-->
      <el-table-column width="100" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">{{user.entId===scope.row.entId ? "编辑" : "查看"}}</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180" v-if="user.entId===scope.row.entId">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" :is-add="isAdd" />
    <import-form ref="import" />
  </div>
</template>

<script>
import { del } from "@/api/unit";
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import { mapGetters } from "vuex";
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import eForm from "./form";
import importForm from "./import-form";

export default {
  components: { eForm, importForm },
  mixins: [initData, initDict],
  data() {
    return {
      delLoading: false,
      loading: false,
      query: {
        value: null,
        status: null,
      },
    };
  },
  created() {
    this.getDictMap("unit_group").then((_) => {
      this.init();
    });
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/unit";
      const sort = "createAt";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;

      if (query.value) {
        this.params["name"] = query.value;
      }
      if (query.status) {
        this.params["groupCode"] = query.status;
      }
      return true;
    },
    getGroupName(row) {
      if (this.dictMap.unit_group) {
        let dict = this.dictMap.unit_group.find((o) => {
          return o.id === row.groupCode;
        });
        if (dict) return dict.label;
        else return "";
      }
      return "";
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    //修改
    edit(data) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.restForm(JSON.parse(JSON.stringify(data)));
    },
    doImport() {
      this.$refs.import && this.$refs.import.show();
    },
    //删除当前行数据功能
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
  },
};
</script>