import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/unit',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/unit/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/unit',
    method: 'put',
    data
  })
}

export function batchImport(data) {
  return request({
    url: "api/units",
    method: "post",
    data
  })
}

export function getByErpCode(erpCode) {
  return request({
    url: "api/unit/getByErpCode",
    method: "get",
    params: {
      code: erpCode
    }
  })
}