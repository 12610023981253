<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="导入计量单位" width="760px" @closed="handleClosed">
    <div class="h">
      <el-input v-model.trim="query.name" :maxlength="20" placeholder="输入计量单位名称搜索" style="width: 180px;" />
    </div>
    <el-table ref="list" :data="filteredData" row-key="erpId" v-loading="loading" @selection-change="handleSelectionChange" height="480px">
      <el-table-column type="selection" width="35" reserve-selection />
      <el-table-column prop="erpCode" label="ERP编码" width="100" />
      <el-table-column prop="name" label="计量单位名称" />
    </el-table>

    <div slot="footer">
      <el-button type="primary" :loading="importing" :disabled="!selection || !selection.length" @click="handleSubmit">开始导入</el-button>
      <el-button @click="dialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";
import { batchImport } from "@/api/unit";

export default {
  mixins: [initData],
  data() {
    return {
      importing: false,
      dialog: false,
      selection: null
    };
  },
  computed: {
    filteredData() {
      let ds = [];
      if (this.data && this.data.length) {
        if (this.query.name) {
          ds = this.data.filter(o => {
            return o.name.indexOf(this.query.name) >= 0;
          });
        } else {
          ds = this.data;
        }
      }
      return ds;
    }
  },
  methods: {
    beforeInit() {
      this.url = "api/unit/batchFromErp";
      this.params = Object.assign({}, this.query);
      return true;
    },
    handleClosed() {
      this.selection = null;
      this.$refs.list && this.$refs.list.clearSelection();
    },
    handleSelectionChange(sel) {
      this.selection = sel;
    },
    handleSubmit() {
      if (this.selection && this.selection.length) {
        this.importing = true;
        batchImport(this.selection)
          .then(res => {
            this.$notify({
              title: "导入计量单位成功",
              type: "success",
              duration: 2500
            });
            this.$parent.init();
            this.dialog = false;
          })
          .finally(_ => {
            this.importing = false;
          });
      }
    },
    show() {
      this.dialog = true;
    }
  },
  mounted() {
    this.init();
  }
};
</script>